import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import imageCompression from "browser-image-compression";

export const useAddStore = ({
    initialValues,
    registeredStoresAction,
    responseRegisteredStores,
    editStoresAction,
    responseEditStores,
    selectedRowStore,
    responseDirectoryStoreExists,
    directoryStoresExistsAction,
    responseDirectoryStoreOmvsReducer,
    directoryStoresOmvsAction,
    dataRowEdit,
    zipCodeAddressStoreAction,
    responseZipCodeAddressStoreReducer,
    cleanZipCodeAddressStore,
    cleanIsRegisteredStores,
    cleanIsEditStores,
}) => {
    const responseZipCode = responseZipCodeAddressStoreReducer?.payload;
    const is_registered = responseDirectoryStoreExists?.payload?.is_registered;
    const { payload } = responseDirectoryStoreOmvsReducer || {};
    const { omvs } = payload || {};
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
    const [file, setFile] = useState(null);
    const [openingHours, setOpeningHours] = useState([]);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [location, setLocation] = useState({
        latitude: dataRowEdit?.latitude,
        longitude: dataRowEdit?.longitude,
    });
    const [warningsDialogOpen, setWarningsDialogOpen] = useState(false);
    const [storeSearched, setStoreSearched] = useState(false);
    const [selectedOmv, setSelectedOmv] = useState("");
    const [state, setState] = useState("");
    const [municipality, setMunicipality] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [toggleState, setToggleState] = useState(
        dataRowEdit?.is_published || false
    );

    const handleToggleChange = (event) => {
        setToggleState(event.target.checked);
    };

    useEffect(() => {
        if (responseEditStores?.payload?.message === "Success") {
            setResponseMessage("Tienda actualizada correctamente");
            setLoading(false);
            setSuccessDialogOpen(true);
        }
        if (responseEditStores?.payload?.response?.status > 300) {
            setResponseMessage(
                responseEditStores?.payload?.response?.data?.detail ||
                    responseEditStores?.payload?.message
            );
            setLoading(false);
            setSuccessDialogOpen(true);
        }
    }, [responseEditStores]);

    useEffect(() => {
        if (responseRegisteredStores?.payload?.message === "Success") {
            cleanIsRegisteredStores();
            setSuccessDialogOpen(true);
            setResponseMessage(
                responseRegisteredStores?.payload?.response?.data?.detail ||
                    responseRegisteredStores?.payload?.message
            );
            setLoading(false);
            resetForm();
        } else if (responseRegisteredStores?.payload?.response?.status > 300) {
            setResponseMessage(
                responseRegisteredStores?.payload?.response?.data?.detail
            );
            setWarningsDialogOpen(true);
            setLoading(false);
        }
    }, [responseRegisteredStores]);

    useEffect(() => {
        if (responseDirectoryStoreExists?.status) {
            setLoading(false);
        } else if (
            responseDirectoryStoreExists?.payload?.response?.status > 300
        ) {
            setResponseMessage(
                responseDirectoryStoreExists?.payload?.response?.data?.detail
            );
        }
    }, [responseDirectoryStoreExists]);

    useEffect(() => {
        if (dataRowEdit) {
            setLoading(true);
            setToggleState(dataRowEdit.is_published);
        }
    }, [dataRowEdit]);

    // Mostrar el diálogo si la tienda ya está registrada
    useEffect(() => {
        if (is_registered === true) {
            setDialogOpen(true);
        }
    }, [is_registered, responseDirectoryStoreExists]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await directoryStoresOmvsAction();
            setLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (omvs) {
            if (responseDirectoryStoreOmvsReducer?.status === 200) {
                setLoading(false);
            } else if (responseDirectoryStoreOmvsReducer?.status > 300) {
                setLoading(false);
                setResponseMessage(
                    "No tiene permisos para acceder a esta sección"
                );
            }
        }
    }, [omvs, responseDirectoryStoreOmvsReducer]);

    useEffect(() => {
        if (initialValues) {
            Object.keys(initialValues).forEach((key) => {
                setValue(key, initialValues[key]);
            });
        }
    }, [initialValues, setValue]);

    useEffect(() => {
        if (dataRowEdit) {
            setToggleState(dataRowEdit.is_published);
        }
    }, [dataRowEdit]);

    const onSubmit = async (data) => {
        setLoading(true);

        const formData = new FormData();

        if (!initialValues) {
            formData.append("omv_id", data.omv);
            let externalId = data.establishmentId || "";

            if (data.omv === 283) {
                externalId = `${data.mtcId}-${data.establishmentId}`;
            } else {
                externalId = data.establishmentId || "";
            }
            formData.append("external_id", externalId);
        }

        formData.append("store_name", data.storeName || "");
        formData.append("phone_number", data.phoneNumber || "");
        formData.append("email", data.email || "");
        formData.append("state", data.state || "");
        formData.append("municipality", data.municipality || "");
        formData.append("neighborhood", data.neighborhood || "");
        formData.append("address", data.street || "");
        formData.append("zipcode", data.postalCode || "");
        formData.append("address_reference", data.reference || "");
        formData.append("opening_hours", JSON.stringify(openingHours) || "[]");
        formData.append("latitude", data.latitude);
        formData.append("longitude", data.longitude);
        formData.append("published", toggleState);

        // Si hay archivo, comprimirlo y adjuntarlo
        if (file) {
            try {
                const options = {
                    maxWidthOrHeight: 600,
                    useWebWorker: true,
                    fileType: "image/jpeg", // Convierte a JPG si no es JPG
                };
                const compressedFile = await imageCompression(file, options);
                const finalFile = new File([compressedFile], file.name, {
                    type: "image/jpeg",
                });
                formData.append("file", finalFile);
            } catch (error) {
                console.error("Error al comprimir la imagen:", error);
            }
        } else if (data.image && data.image.length > 0) {
            try {
                const options = {
                    maxWidthOrHeight: 600,
                    useWebWorker: true,
                    fileType: "image/jpeg", // Convierte a JPG si no es JPG
                };
                const compressedFile = await imageCompression(
                    data.image[0],
                    options
                );
                const finalFile = new File(
                    [compressedFile],
                    data.image[0].name,
                    {
                        type: "image/jpeg",
                    }
                );
                formData.append("file", finalFile);
            } catch (error) {
                console.error("Error al comprimir la imagen:", error);
            }
        }

        initialValues
            ? editStoresAction(formData, selectedRowStore)
            : registeredStoresAction(formData);
    };

    const handleLocationChange = (latitude, longitude) => {
        setLocation({ latitude, longitude });
    };

    const handleFormSubmit = (data) => {
        const updatedData = {
            ...data,
            latitude: location.latitude,
            longitude: location.longitude,
        };
        onSubmit(updatedData);
    };

    const handleSearchStore = () => {
        setLoading(true);
        const data = getValues();
        let externalId = data.establishmentId || "";

        if (data.omv === 283) {
            externalId = `${data.mtcId}-${data.establishmentId}`;
        }

        directoryStoresExistsAction({
            omv_id: data.omv,
            external_id: externalId,
        });
    };

    const handleOmvChange = (event) => {
        setSelectedOmv(event.target.value);
        setStoreSearched(false);
        resetForm();
    };

    const resetForm = () => {
        cleanZipCodeAddressStore();
        setOpeningHours([]); // Limpiar los horarios seleccionados
        setFile(null); // Limpiar la imagen seleccionada
        setLocation({ latitude: null, longitude: null }); // Limpiar la ubicación seleccionada
        setState(""); // Limpiar el estado
        setMunicipality(""); // Limpiar el municipio
        setNeighborhood(""); // Limpiar la colonia
        setValue("postalCode", ""); // Limpiar el código postal
        reset({
            storeName: "",
            phoneNumber: "",
            email: "",
            state: "",
            municipality: "",
            neighborhood: "",
            street: "",
            postalCode: "",
            reference: "",
            latitude: "",
            longitude: "",
            establishmentId: "",
            mtcId: "",
            omv: "",
        });
    };

    const handleZipcodeSearch = () => {
        setLoading(true);
        const data = getValues();
        zipCodeAddressStoreAction(data.postalCode);
        console.log("Buscando código postal", data.postalCode);
    };

    useEffect(() => {
        if (responseZipCode) {
            setLoading(false);
        }
    }, [responseZipCode]);

    return {
        register,
        handleSubmit,
        errors,
        setFile,
        setOpeningHours,
        openingHours,
        onSubmit,
        setValue,
        trigger,
        loading,
        responseMessage,
        handleSearchStore,
        omvs,
        is_registered,
        dialogOpen,
        setDialogOpen,
        successDialogOpen,
        setSuccessDialogOpen,
        location,
        setLocation,
        storeSearched,
        setStoreSearched,
        handleLocationChange,
        handleFormSubmit,
        handleZipcodeSearch,
        responseZipCode,
        handleOmvChange,
        selectedOmv,
        state,
        municipality,
        neighborhood,
        warningsDialogOpen,
        setWarningsDialogOpen,
        toggleState,
        setToggleState,
        handleToggleChange,
    };
};
