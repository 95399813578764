import React from "react";
import {
    Button,
    CircularProgress,
    Switch,
    FormControlLabel,
} from "@mui/material";

import {
    buttonStyle,
    loadingStyle,
    circularProgressStyle,
} from "../style/style";
import StoreDetails from "./StoreDetails";
import StoreContact from "./StoreContact";
import { Schedule } from "./schedule/Schedule";
import { StoreImageUpload } from "./StoreImageUpload";
import StoreAddress from "./StoreAddress";
import StoreMap from "utils/StoreMap";
import Dialogs from "./Dialogs";

const AddStoreForm = ({
    initialValues,
    register,
    handleSubmit,
    errors,
    setFile,
    setOpeningHours,
    setValue,
    trigger,
    openingHours,
    loading,
    responseMessage,
    handleSearchStore,
    omvs,
    is_registered,
    dialogOpen,
    setDialogOpen,
    successDialogOpen,
    setSuccessDialogOpen,
    location,
    storeSearched,
    setStoreSearched,
    handleLocationChange,
    handleFormSubmit,
    handleZipcodeSearch,
    responseZipCode,
    handleOmvChange,
    selectedOmv,
    warningsDialogOpen,
    setWarningsDialogOpen,
    toggleState,
    handleToggleChange,
    dataRowEdit,
    setdataRowEdit,
    cleanIsEditStores,
    handleClose,
    cleanIsRegisteredStores,
}) => {
    return (
        <>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                {!initialValues && (
                    <StoreDetails
                        handleOmvChange={handleOmvChange}
                        selectedOmv={selectedOmv}
                        loading={loading}
                        omvs={omvs}
                        register={register}
                        errors={errors}
                        handleSearchStore={() => {
                            handleSearchStore();
                            setStoreSearched(true);
                        }}
                        setStoreSearched={setStoreSearched}
                    />
                )}
                {(initialValues ||
                    (is_registered !== true &&
                        is_registered !== undefined &&
                        storeSearched)) && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={toggleState}
                                        onChange={handleToggleChange}
                                        color="primary"
                                    />
                                }
                                label="Tienda Publicada"
                            />
                        </div>
                        <StoreContact
                            register={register}
                            errors={errors}
                            dataRowEdit={dataRowEdit}
                        />
                        <Schedule
                            setValue={setValue}
                            setOpeningHours={setOpeningHours}
                            openingHours={openingHours}
                            dataRowEdit={dataRowEdit}
                            setdataRowEdit={setdataRowEdit}
                        />
                        <StoreImageUpload
                            trigger={trigger}
                            errors={errors}
                            register={register}
                            setFile={setFile}
                            setValue={setValue}
                            dataRowEdit={dataRowEdit}
                        />
                        <StoreAddress
                            setValue={setValue}
                            loading={loading}
                            responseZipCode={responseZipCode}
                            handleZipcodeSearch={handleZipcodeSearch}
                            register={register}
                            errors={errors}
                            dataRowEdit={dataRowEdit}
                        />
                        <StoreMap
                            latitude={location.latitude}
                            longitude={location.longitude}
                            onLocationChange={handleLocationChange}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                            sx={buttonStyle}
                        >
                            {initialValues
                                ? "Editar tienda"
                                : "Registrar tienda"}
                        </Button>
                        {loading && (
                            <div style={loadingStyle}>
                                <CircularProgress
                                    style={circularProgressStyle}
                                />
                            </div>
                        )}
                    </>
                )}
            </form>
            <Dialogs
                cleanIsRegisteredStores={cleanIsRegisteredStores}
                cleanIsEditStores={cleanIsEditStores}
                setWarningsDialogOpen={setWarningsDialogOpen}
                warningsDialogOpen={warningsDialogOpen}
                handleClose={handleClose}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                successDialogOpen={successDialogOpen}
                setSuccessDialogOpen={setSuccessDialogOpen}
                responseMessage={responseMessage}
            />
        </>
    );
};

export default AddStoreForm;
